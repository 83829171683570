<template>
  <div>
    <!--- Navigator-->
    <b-row>
      <b-col cols="6">
        <h3><b>จัดการลูกค้า</b></h3>
        <small>
          <span
            class="text-primary"
            style="cursor: pointer"
            @click="$router.push('/setting/customer/owner')"
            ><b>จัดการลูกค้า</b> ></span
          >
          <span
            class="text-primary"
            @click="$router.push(navPath('company'))"
            style="cursor: pointer"
            ><b> {{ $route.query.ownerName }}</b> ></span
          >
          <span
            class="text-primary"
            @click="$router.push(navPath('subCompany'))"
            style="cursor: pointer"
            ><b> {{ $route.query.companyName }}</b> ></span
          >
          <span
            class="text-primary"
            @click="$router.push(navPath('previous'))"
            style="cursor: pointer"
            ><b> {{ $route.query.subCompanyName }}</b> ></span
          >

          <b>สาขา</b>
        </small>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center">
        <b-button
          v-if="customerPermission == 'create' || customerPermission == 'edit'"
          variant="primary"
          size="sm"
          style="height: 35px"
          class="mr-1"
          @click="save"
        >
          <feather-icon icon="SaveIcon" />
          บันทึก
        </b-button>

        <b-button
          variant="outline-dark"
          size="sm"
          @click="$router.push(navPath('previous'))"
          style="height: 35px"
        >
          <feather-icon icon="ArrowLeftIcon" />
          ย้อนกลับ
        </b-button>
      </b-col>
    </b-row>
    <!--- Navigator-->

    <b-row class="mt-1">
      <b-col cols="12">
        <!-- START SECTION FORM วันเดินทาง--->
        <b-card>
          <b-row>
            <b-col
              ><h2>
                <b v-if="$route.query.bid">แก้ไขสาขา</b>
                <b v-else>เพิ่มสาขา</b>
              </h2></b-col
            >
          </b-row>
          <!--- START (ชื่อสาขา) --->
          <b-row class="mt-2">
            <b-col md="3" class="">
              <label>รหัสสาขา <span class="text-danger">*</span></label>
              <b-form-input
                id="branchCode"
                placeholder="Vserv"
                size="sm"
                v-model="branchData.branchCode"
              />
            </b-col>

            <b-col class="">
              <label>ชื่อสาขา <span class="text-danger">*</span></label>
              <b-form-input
                id="branchName"
                placeholder="Vserv"
                size="sm"
                v-model="branchData.branchName"
              />
            </b-col>

            <b-col class="">
              <label>ชื่อผู้ติดต่อ</label>
              <b-form-input
                placeholder="คุณกรณ์"
                size="sm"
                v-model="branchData.contactName"
              />
            </b-col>
          </b-row>
          <!--- END (ชื่อสาขา) --->

          <!--- START (เบอร์โทร) --->
          <b-row class="mt-1">
            <b-col md="3" class="">
              <label>เบอร์โทร</label>
              <b-form-input
                placeholder="0987654321"
                size="sm"
                value="0987654321"
                v-model="branchData.tel"
              />
            </b-col>

            <b-col md="3" class="">
              <label>Email</label>
              <b-form-input
                placeholder="admin@gmail.com"
                size="sm"
                value="admin@gmail.com"
                v-model="branchData.email"
              />
            </b-col>
            <b-col md="2" class="">
              <label>ศูนย์บริการ</label>
              <v-select
                id="brand"
                v-model="zoneSelected"
                label="title"
                :options="zoneOption"
                :clearable="false"
                class="select-size-sm"
                @input="onChangeZone"
              />
            </b-col>

            <b-col md="2" class="">
              <label>จังหวัด</label>
              <v-select
                id="brand"
                v-model="provinceSelected"
                label="province_name"
                :options="provinceOption"
                :clearable="false"
                class="select-size-sm"
              />
            </b-col>

            <b-col md="2" class="">
              <label>เขต</label>
              <v-select
                id="brand"
                v-model="countrySelected"
                label="name"
                :options="countryList"
                :clearable="false"
                class="select-size-sm"
                :disabled="provinceSelected && provinceSelected.id !== 2"
              />
            </b-col>
          </b-row>
          <!--- END (เบอร์โทร) --->

          <!--- START ROW ที่อยู่ --->
          <b-row class="mt-1">
            <b-col md="6">
              <label>ที่อยู่</label>
              <b-form-textarea
                id="textarea-default"
                placeholder="ที่อยู่"
                rows="2"
                size="sm"
                v-model="branchData.address"
              />
            </b-col>

            <b-col md="6">
              <label>หมายเหตุ</label>
              <b-form-textarea
                id="textarea-default"
                placeholder="หมายเหตุ"
                rows="2"
                size="sm"
                v-model="branchData.remark"
              />
            </b-col>
          </b-row>
          <!--- END ที่อยู่ --->

          <!--- START (เบอร์โทร) --->
          <b-row class="mt-1">
            <b-col md="3" class="">
              <label>Latitude</label>
              <b-form-input
                placeholder="1.0002292"
                size="sm"
                value="1.0002292"
                v-model="branchData.latitude"
              />
            </b-col>

            <b-col md="3" class="">
              <label>Longtitude</label>
              <b-form-input
                placeholder="1.223444"
                size="sm"
                value="1.223444"
                v-model="branchData.longtitude"
              />
            </b-col>
            <b-col md="3" class="">
              <label>วันหมดประกัน</label>
              <b-form-datepicker
                id="example-datepicker"
                locale="th-TH"
                show-decade-nav
                class="mb-1"
                size="sm"
                v-model="branchData.warrantyExpireDate"
                today-button
              />
             
            </b-col>
            <b-col md="2" class="">
              <label>สถานะ</label>
              <b-form-group>
                <b-form-radio-group
                  :options="statusOptions"
                  name="radio-inline"
                  v-model="branchData.status"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!--- END (เบอร์โทร) --->
        </b-card>
        <!-- END SECTION FORM วันเดินทาง--->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BButton,
  BFormCheckbox,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BFormRadioGroup,
    BFormDatepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

//SERVICES
import CustomerService from "@/services/setting/customer";
import OtherService from "@/services/other";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BButton,
    BFormInput,
    BInputGroup,
    BFormCheckbox,
    BFormTextarea,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormRadioGroup,
      BFormDatepicker,
    vSelect,
  },
  data() {
    return {
      statusSelected: 1,
      statusOptions: [
        { text: "เปิด", value: 1 },
        { text: "ปิด", value: 0 },
      ],
      zoneSelected: { title: "=== เลือกภาค ===", value: "" },
      zoneOption: [
        { title: "=== เลือกภาค ===", value: "" },
        { title: "ภาคเหนือ", value: "n" },

        // { title: "ภาคกลางตอน(บน)" , value:"cn"},
        { title: "ภาคตะวันออกเฉียงเหนือ", value: "ne" },
        // { title: "ภาคตะวันออกเฉียงเหนือ(บน)", value:"en" },
        // { title: "ภาคตะวันออกเฉียงเหนือ(ล่าง)", value:"es" },
        { title: "ภาคกลาง", value: "c" },
        { title: "ภาคตะวันออก", value: "e" },
        { title: "ภาคตะวันตก", value: "w" },
        { title: "ภาคใต้", value: "s" },
      ],
      provinceSelected: { province_name: "=== เลือกจังหวัด ===", id: 0 },
      provinceOption: [],
      provinceInit: [],
      branchData: {
        branchName: "",
        branchCode: "",
        tel: "",
        email: "",
        zoneId: 0,
        provinceId: 0,
        address: "",
        remark: "",
        latitude: 0,
        longtitude: 0,
        gasRate: 0,
        status: 1,
      },
      countrySelected: { id: 0, name: "=== เลือกเขต ===" },
      countryList: [],
      branchCodeInit:""
    };
  },

  computed: {
    customerPermission() {
      const permission = this.$store.getters["user/getPermissionPage"]("ST003");
      if (permission == "disable") {
        localStorage.removeItem("userData");
        window.location.href = "/not-authorized";
      }
      return permission;
    },
    mode() {
      const branchId = this.$route.query.bid;
      if (branchId) {
        return "edit";
      }
      return "create";
    },
    paramBranchId() {
      return this.$route.query.bid;
    },
    paramSubCompanyId() {
      return this.$route.query.scid;
    },
  },

  watch: {
    provinceSelected() {
      //NOT BANGKOK
      if (this.provinceSelected && this.provinceSelected?.id !== 2) {
        this.countrySelected = { id: 0, name: "=== เลือกเขต ===" };
      }
    },
  },

  methods: {
    navPath(type) {
      switch (type) {
        case "previous":
          return `/setting/customer/branch?oid=${this.$route.query.oid}&cid=${this.$route.query.cid}&scid=${this.$route.query.scid}&ownerName=${this.$route.query.ownerName}&companyName=${this.$route.query.companyName}&subCompanyName=${this.$route.query.subCompanyName}`;
        case "subCompany":
          return `/setting/customer/sub-company?oid=${this.$route.query.oid}&cid=${this.$route.query.cid}&ownerName=${this.$route.query.ownerName}&companyName=${this.$route.query.companyName}&subCompanyName=${this.$route.query.subCompanyName}`;
        case "company":
          return `/setting/customer/company?oid=${this.$route.query.oid}&cid=${this.$route.query.cid}&ownerName=${this.$route.query.ownerName}&companyName=${this.$route.query.companyName}`;

        default:
          break;
      }
    },
    async save() {
      if (!this.branchData.branchCode) {
        alert("กรุณากรอกรหัสสาขา");
        return;
      }

      if (!this.branchData.branchName) {
        alert("กรุณากรอกชื่อสาขา");
        return;
      }

      if (this.mode == "create") {
        await this.insert();
      } else {
        await this.update();
      }
    },

    async checkBranchCode(branchCode, queryString){
      const result = await CustomerService.CheckDupBranchCode(branchCode, queryString)
      if(result.data.isDuplicate){
        this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Customer Branch`,
                icon: "XIcon",
                variant: "danger",
                text: `ไม่สามารถใช้รหัสสาขาซ้ำกับที่อื่นได้`,
              },
            });
            return true
      }

      return false
    },

    async getCustomerBranchDetail() {
      const result = await CustomerService.GetCustomerBranchById(
        this.paramBranchId
      );
      this.branchData = {
        ...result.data,
        //warrantyExpireDate:result.data.warrantyExpireDate == "0000-00-00" ? new Date() : result.data.warrantyExpireDate
        }
      this.branchCodeInit = result.data.branchCode
      this.restoreProvince();
      this.restoreContry();
    },
    async getCountry() {
      const result = await CustomerService.GetCountry();
      this.countryList = [
        { id: 0, name: "=== เลือกเขต ===" },
        ...result.data.data,
      ];
    },
    async insert() {
      const isDup = await this.checkBranchCode(this.branchData.branchCode, `mode=stock`)
      if(isDup){
        return
      }
      this.isLoading = true;
      const result = await CustomerService.InsertCustomerBranch({
        ...this.branchData,
        subCompanyId: this.paramSubCompanyId,
        zoneId: this.zoneSelected.value || 0,
        provinceId: this.provinceSelected.id || 0,
        countryId: this.countrySelected.id || 0,
        mode: "stock",
      });
      if (result.status == "success") {         
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Customer Branch`,
            icon: "CheckIcon",
            variant: "success",
            text: `เพิ่มรายการเรียบร้อย`,
          },
        });
        this.$router.push(this.navPath("previous"));    
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Customer Branch`,
            icon: "XIcon",
            variant: "danger",
            text: `พบปัญหาในการเพิ่มรายการ`,
          },
        });
      }

      this.isLoading = false;
    },
    async update() {
      if(this.branchCodeInit !==this.branchData.branchCode){
        const isDup = await this.checkBranchCode(this.branchData.branchCode, `mode=stock`)
        if(isDup){
          return
        }
      }

      this.isLoading = true;
      const obj = {
        ...this.branchData,
        zoneId: this.zoneSelected.value || 0,
        provinceId: this.provinceSelected.id || 0,
        countryId: this.countrySelected.id || 0,
      };

      const result = await CustomerService.UpdateCustomerBranch(
        obj,
        this.paramBranchId
      );

      console.log("created", result.data)
      if (result.status == "success") {
        if(result.data.status=="duplicate"){
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Customer Branch`,
                icon: "XIcon",
                variant: "danger",
                text: `ไม่สามารถใช้รหัสสาขาซ้ำได้`,
              },
            });
        }else{
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Customer Branch`,
              icon: "CheckIcon",
              variant: "success",
              text: `แก้ไขรายการเรียบร้อย`,
            },
          });
          this.$router.push(this.navPath("previous"));
        }

        
    
      } else {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Customer Branch`,
            icon: "XIcon",
            variant: "danger",
            text: `พบปัญหาในการแก้ไขรายการ`,
          },
        });
      }
      this.isLoading = false;
    },

    async getProvinceList() {
      const result = await OtherService.GetProvinceList();
      const proviceResult = [
        { province_name: "=== เลือกจังหวัด ===", id: 0 },
        ...result.data,
      ];

      this.provinceOption = proviceResult;
      this.provinceInit = proviceResult;

      // if (result.data.length > 0) {
      //   this.provinceSelected = { province_name: "=== เลือกจังหวัด ===" , id:0};
      // }
    },

    onChangeZone(item) {
      const provinces = [...this.provinceInit];
      const provinceZone = provinces.filter(
        (f) => f.provice_phase == item.value
      );
      this.provinceOption =
        Object.keys(provinceZone).length > 0 ? provinceZone : provinces;
      this.provinceSelected = { province_name: "=== เลือกจังหวัด ===", id: 0 };
      if (this.mode == "edit") {
        const provinceSelected = provinces.find(
          (f) => f.id == this.branchData.provinceId
        );

        this.provinceSelected = provinceSelected || {
          province_name: "=== เลือกจังหวัด ===",
          id: 0,
        };
      }
    },
    restoreProvince() {
      const zones = [...this.zoneOption];
      const zoneSelected = zones.find((f) => f.value == this.branchData.zoneId);

      this.zoneSelected = zoneSelected || zones[0];
      this.onChangeZone(this.zoneSelected);
    },
    restoreContry() {
      const countryList = [...this.countryList];
      const countrySelected = countryList.find(
        (f) => f.id == this.branchData.countryId
      );
      this.countrySelected = countrySelected || countryList[0];
    },
  },

  async mounted() {
    await this.getProvinceList();
    await this.getCountry();
    if (this.mode == "edit") {
      await this.getCustomerBranchDetail();
    } else {
      //this.onChangeZone({ title: "ภาคกลาง" , value:"c" })
    }
  },
};
</script>
<style scoped>
.table-responsive {
  overflow: none !important;
}
</style>
